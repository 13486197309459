import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import "leaflet-moving-rotated-marker";
import { carTrailerIcon, trailerIcon } from "../../icons";
import styles from "../../styles/TrailerMarker.module.css";
import { Trailer } from "./trailersSlice";
import { translate } from "../../utils/translator";

type TrailerMarkerProps = {
  trailer: Trailer;
  pushPosition: any;
};

function toRad(Value) {
  return (Value * Math.PI) / 180;
}
function toDeg(Value) {
  return (Value * 180) / Math.PI;
}

const TrailerMarker = ({ trailer, pushPosition }: TrailerMarkerProps) => {
  const {
    _id,
    location,
    name,
    description,
    state,
    online_cabinets,
    total_cabinets,
    online_tablets,
    total_tablets,
    remaining_orders,
    total_orders,
  } = trailer;

  const badgeCabinet = getBadgeColor(online_cabinets, total_cabinets);
  const badgeTablet = getBadgeColor(online_tablets, total_tablets);
  const badgeMerchandise = getBadgeColor(remaining_orders, total_orders);

  const [previousLocation, setPreviousLocation] = useState({} as any);
  const [rotation, setRotation] = useState(360);
  useEffect(() => {
    if (previousLocation)
      setRotation(
        calculateBearing(
          previousLocation[1],
          previousLocation[0],
          location[1],
          location[0]
        )
      );
    if (location) {
      setPreviousLocation(location);
      pushPosition(_id, location);
    }
  }, [location]);

  function calculateBearing(
    startLat: number,
    startLng: number,
    endLat: number,
    endLng: number
  ) {
    startLat = toRad(startLat);
    startLng = toRad(startLng);
    endLat = toRad(endLat);
    endLng = toRad(endLng);
    var y = Math.sin(endLng - startLng) * Math.cos(endLat);
    var x =
      Math.cos(startLat) * Math.sin(endLat) -
      Math.sin(startLat) * Math.cos(endLat) * Math.cos(endLng - startLng);
    var brng = Math.atan2(y, x);
    brng = toDeg(brng);
    if (state != "transit") return 0;
    // console.log(startLat, startLng, endLat, endLng);
    console.log(`Angle ${name}: `, 360 - ((brng + 360) % 360));
    return 90 - ((brng + 360) % 360);
  }

  return (
    // <Marker
    //   position={location}
    //   icon={state == "transit" ? carTrailerIcon : trailerIcon}
    //   zIndexOffset={999}
    // >
    // @ts-ignore
    <RotatedMarker
      rotationAngle={rotation}
      rotationOrigin={"center"}
      position={location}
      icon={state == "transit" ? carTrailerIcon : trailerIcon}
      zIndexOffset={999}
    >
      <Popup>
        <>
          <ul className={styles.container + " list-group list-group-flush"}>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Nom</strong>
              <span className="text-right">{name}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Description</strong>
              <span className="text-right">{description}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>État</strong>
              <span className="text-right">{translate(state)}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Armoires connectées</strong>
              <span className={`text-right badge badge-pill ${badgeCabinet}`}>
                {`${online_cabinets}/${total_cabinets}`}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Tablettes connectées</strong>
              <span className={`text-right badge badge-pill ${badgeTablet}`}>
                {`${online_tablets}/${total_tablets}`}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Commandes en cours</strong>
              <span
                className={`text-right badge badge-pill ${badgeMerchandise}`}
              >
                {`${remaining_orders}/${total_orders}`}
              </span>
            </li>
          </ul>
        </>
      </Popup>
      <Tooltip>{name}</Tooltip>
    </RotatedMarker>
  );
};

export default TrailerMarker;

const getBadgeColor = (part: number, total: number) => {
  const val = part / total;
  if (val === 0) {
    return "badge-danger";
  } else if (val === 1) {
    return "badge-success";
  } else {
    return "badge-warning";
  }
};

const RotatedMarker = forwardRef<any>(({ children, ...props }, forwardRef) => {
  const markerRef = useRef();
  const { rotationAngle, rotationOrigin, position, icon, zIndexOffset } =
    props as any;
  const firstPosition = useRef(position);
  useEffect(() => {
    const marker = markerRef.current as any;
    if (marker) {
      if (position.length > 1) {
        marker.slideTo(position, {
          duration: 1500,
          rotationAngle: 15,
        });
      }
      marker.setRotationAngle(rotationAngle);
      marker.setRotationOrigin(rotationOrigin);
    }
  }, [rotationAngle, rotationOrigin, position]);

  return (
    <Marker
      ref={(ref) => {
        markerRef.current = ref as any;
      }}
      position={firstPosition.current}
      icon={icon}
      zIndexOffset={zIndexOffset}
    >
      {children}
    </Marker>
  );
});
