import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";

export type UserRole =
  | "admin"
  | "operator"
  | "manager"
  | "transporter"
  | "provider"
  | "seller";

export type User = NewUser & {
  _id: string;
};

export type NewUser = {
  role: UserRole;
  firstname: string;
  lastname: string;
  email: string;
  company: string;
  address: string;
  phoneNumber: string;
  socialSecurityNumber: string;
  driversLicenseType: string;
  driversLicenseNumber: string;
  deliveredOn: string | null;
  enablePushNotification: boolean;
  deliveredBy: string;
  idCardPhotocopy: boolean;
  driversLicensePhotocopy: boolean;
  furtherInformation: string;
  password: string;
  pointOfSale: string[];
  shop?: { name: string; type: string; url: string };
  proxy?: string;
  companyName?: string;
  companyAddress?: string;
  companyActivity?: string;
  companyWebsite?: string;
};

const usersAdapter = createEntityAdapter<User>({
  selectId: (user) => user._id,
});

const usersSlice = createSlice({
  name: "users",
  initialState: usersAdapter.getInitialState({
    isLoading: false,
  }),
  reducers: {
    usersLoading(state) {
      state.isLoading = true;
    },
    usersLoaded(state) {
      state.isLoading = false;
    },
    setUsers: usersAdapter.setAll,
    removeUser: usersAdapter.removeOne,
    modifyUser: usersAdapter.updateOne,
    addUser: usersAdapter.addOne,
  },
});

export const {
  usersLoading,
  usersLoaded,
  setUsers,
  removeUser,
  modifyUser,
  addUser,
} = usersSlice.actions;

export default usersSlice.reducer;

export const usersSelector = usersAdapter.getSelectors(
  (state: RootState) => state.users
);

export const operatorsSelector = createSelector(
  (state: RootState) => usersSelector.selectAll(state),
  (users) => users.filter((user) => user.role === "operator")
);

export const transportersSelector = createSelector(
  (state: RootState) => usersSelector.selectAll(state),
  (users) => users.filter((user) => user.role === "transporter")
);

export const adminsSelector = createSelector(
  (state: RootState) => usersSelector.selectAll(state),
  (users) => users.filter((user) => user.role === "admin")
);

export const managersSelector = createSelector(
  (state: RootState) => usersSelector.selectAll(state),
  (users) => users.filter((user) => user.role === "manager")
);
