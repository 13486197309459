import { AppThunk } from "../../app/store";
import axios, { AxiosError } from "axios";
import { SERVER_URL } from "../../utils/constants";
import {
  removeTrailer,
  Trailer,
  trailersLoaded,
  trailersLoading,
  modifyTrailer,
  addTrailer,
  setTrailers,
  MaintenanceToDo,
} from "./trailersSlice";
import { toast } from "react-toastify";
import handleAPIError from "../../utils/handleAPIError";

export const ApiGetTrailers = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(trailersLoading());
    const { data }: any = await axios.get(`${SERVER_URL}/trailer/getall`);
    dispatch(setTrailers(data));
    dispatch(trailersLoaded());
  } catch (err) {
    dispatch(trailersLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiGetTrailersFromPOS =
  (city: string, postCode: string, posId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(trailersLoading());
      const { data }: any = await axios.get(
        `${SERVER_URL}/trailer/getByPointOfSale/${city}&${postCode}&${posId}`
      );
      dispatch(setTrailers(data));
      dispatch(trailersLoaded());
    } catch (err) {
      dispatch(trailersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiRemoveTrailer =
  (_id: string, posId?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(trailersLoading());
      await axios.delete(`${SERVER_URL}/trailer/delete`, {
        data: { _id, posId },
      });
      dispatch(removeTrailer(_id));
      toast.success("Suppression réussie");
      dispatch(trailersLoaded());
    } catch (err) {
      dispatch(trailersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiUpdateTrailer =
  (trailer: Trailer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(trailersLoading());

      const { data } = await axios.post(
        `${SERVER_URL}/trailer/update`,
        trailer
      );
      dispatch(modifyTrailer(data.trailer));
      toast.success(data.msg);
      dispatch(trailersLoaded());
    } catch (err) {
      dispatch(trailersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiCreateTrailer =
  (trailer: Trailer, posId?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(trailersLoading());

      const { data } = await axios.post(`${SERVER_URL}/trailer/create`, {
        trailer,
        posId,
      });

      dispatch(addTrailer(data.trailer));
      toast.success("Remorque créée");
      dispatch(trailersLoaded());
    } catch (err) {
      dispatch(trailersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiSetMaintenance =
  (
    trailerId: string,
    cabinetId: string,
    lockerId: string,
    maintenanceToDo: MaintenanceToDo
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(trailersLoading());

      const { data } = await axios.post(
        `${SERVER_URL}/trailer/setMaintenanceToDo`,
        {
          trailerId,
          cabinetId,
          lockerId,
          maintenanceToDo,
        }
      );

      dispatch(modifyTrailer(data.trailer));
      toast.success("Ajout de la maintenance réussie");
      dispatch(trailersLoaded());
    } catch (err) {
      dispatch(trailersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiRemoveMaintenance =
  (trailerId: string, cabinetId: string, lockerId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(trailersLoading());

      const { data } = await axios.post(
        `${SERVER_URL}/trailer/deleteMaintenanceToDo`,
        {
          trailerId,
          cabinetId,
          lockerId,
        }
      );

      dispatch(modifyTrailer(data.trailer));
      toast.success("Suppression de la maintenance réussie");
      dispatch(trailersLoaded());
    } catch (err) {
      dispatch(trailersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiSendConfigToTrailer =
  (trailerId: string): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${SERVER_URL}/trailer/sendConfigToTrailer`,
        {
          trailerId,
        }
      );
      toast.success(data.msg);
    } catch (err: AxiosError | any) {
      console.error(err.response);
      if (err.response) {
        toast.error(err.response.data.msg);
      } else {
        toast.error("Erreur de lors de la requête");
      }
    }
  };

export const ApiSendOrdersToTrailer = async (
  trailerId: string,
  pointOfSaleId: string,
  date: string
) => {
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/trailer/sendOrdersToTrailer`,
      {
        trailerId,
        pointOfSaleId,
        date,
      }
    );
    toast.success(data.msg);
  } catch (err: AxiosError | any) {
    console.error(err.response);
    if (err.response) {
      toast.error(err.response.data.msg);
    } else {
      toast.error("Erreur de lors de la requête");
    }
  }
};

export const ApiDownloadMaintenancesSummary = async () => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/trailer/downloadMaintenancesSummary`,
      {
        responseType: "blob",
      }
    );

    const filename = response.headers["content-disposition"].split('"')[1];

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (err: AxiosError | any) {
    console.error(err.response);
    if (err.response) {
      toast.error(err.response.data.msg);
    } else {
      toast.error("Erreur de lors de la requête");
    }
  }
};
