import { startOfDay, endOfDay, differenceInMinutes } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { getWeek, getWeekYear } from "date-fns";
import { RootState } from "../../app/rootReducer";
import { POS } from "../pointOfSales/posSlice";

export type TransportType = "outward" | "return";

export type UnavailableTransporterAndTrailer = {
  transporter: string;
  trailer: string;
  date: string;
  transportType: TransportType;
  pointOfSale: {
    _id: string;
    name: string;
    cityId: {
      _id: string;
      name: string;
    };
  };
};

export type PlanningEntry = {
  _id: string;
  date: string;
  disableWithdrawDate: string;
  transportType: TransportType;
  operator: string;
  transporter: string;
  // the cityId
  city: string;
  pointOfSale: string | POS;
  trailer: { _id: string; name: string } | string;
};

const planningAdapter = createEntityAdapter<PlanningEntry>({
  selectId: (entry) => entry._id,
});

type Status = "initial" | "pending" | "fetched";
const status: Status = "initial";

const planningSlice = createSlice({
  name: "planning",
  initialState: planningAdapter.getInitialState<{
    status: Status;
    selectedCityId: string;
    unavailableTransportersAndTrailers: UnavailableTransporterAndTrailer[];
  }>({
    status,
    selectedCityId: "",
    unavailableTransportersAndTrailers: [],
  }),
  reducers: {
    planningLoading(state) {
      state.status = "pending";
    },
    planningLoaded(state) {
      state.status = "fetched";
    },
    setSelectedCityId(state, action: PayloadAction<string>) {
      state.selectedCityId = action.payload;
    },
    setPlanning: planningAdapter.setAll,
    upsertManyPlanning: planningAdapter.upsertMany,
    removePlanningEntry: planningAdapter.removeOne,
    removePlanningEntries: planningAdapter.removeMany,
    modifyPlanningEntry: planningAdapter.updateOne,
    addPlanningEntry: planningAdapter.addOne,
    addPlanningEntries: planningAdapter.addMany,
    setUnavailableTransporters(
      state,
      action: PayloadAction<UnavailableTransporterAndTrailer[]>
    ) {
      state.unavailableTransportersAndTrailers = action.payload;
    },
  },
});

export const {
  planningLoading,
  planningLoaded,
  setPlanning,
  upsertManyPlanning,
  removePlanningEntry,
  removePlanningEntries,
  modifyPlanningEntry,
  addPlanningEntry,
  addPlanningEntries,
  setUnavailableTransporters,
  setSelectedCityId,
} = planningSlice.actions;

export default planningSlice.reducer;

export const planningSelectors = planningAdapter.getSelectors(
  (state: RootState) => state.planning
);

export const selectPlanningByWeekYearAndCity = (
  cityId: string,
  year: number,
  weekNumber: number
) => {
  return createSelector([planningSelectors.selectAll], (items) =>
    items.filter((planning) => {
      const planningDate = new Date(planning.date);
      if (
        planning.city === cityId &&
        getWeekYear(planningDate, { weekStartsOn: 1 }) === year &&
        getWeek(planningDate, { weekStartsOn: 1 }) === weekNumber
      ) {
        return planning;
      }
      return undefined;
    })
  );
};

export const selectPlanningByDateAndTrailerId = (
  date: Date,
  trailerId: string
) => {
  return createSelector([planningSelectors.selectAll], (items) => {
    let planningOfDay = [] as any;

    const inputZoned = zonedTimeToUtc(
      utcToZonedTime(date, "Europe/Paris"),
      "Europe/Paris"
    );
    // const inputZoned = utcToZonedTime(
    //   new Date("2022-10-19T08:25:00.000Z"),
    //   "Europe/Paris"
    // );
    // const inputZoned = utcToZonedTime(new Date(date), "Europe/Paris");
    // console.log("inputZoned :", inputZoned.toISOString());
    const dayStartZoned = startOfDay(inputZoned);
    const dayStart = zonedTimeToUtc(
      dayStartZoned,
      "Europe/Paris"
    ).toISOString();
    const dayEndZoned = endOfDay(inputZoned);
    const dayEnd = zonedTimeToUtc(dayEndZoned, "Europe/Paris").toISOString();

    // console.log("dayStart", dayStart);
    // console.log("dayEnd", dayEnd);

    for (let planning of items) {
      if (typeof planning.trailer !== "string")
        if (planning.trailer._id == trailerId) {
          if (planning.transportType == "outward") {
            // if (planning.date > dayStart && planning.date < dayEnd)
            if (planning.date > inputZoned.toISOString())
              planningOfDay.push(planning);
          } else {
            // if (planning.date > dayStart && planning.date < dayEnd)
            if (planning.date > inputZoned.toISOString())
              planningOfDay.push(planning);
          }
        }
    }
    let toReturn = {} as any;
    planningOfDay.sort((a, b) => (a.date >= b.date ? 1 : -1));
    // console.log(planningOfDay);
    planningOfDay.splice(2); //
    if (planningOfDay[0])
      if (planningOfDay[0].transportType == "return") planningOfDay.splice(1); // Si le premier est retour ca veut dire on est encore dans la livraison alors on supprime l'aller suivant
    for (const planning of planningOfDay) {
      if (planning.transportType == "outward") toReturn.start = planning.date;
      if (planning.transportType == "return") {
        toReturn.end = planning.date;
        toReturn.pointOfSale = planning.pointOfSale;
      }
    }
    if (!toReturn.start && toReturn.end) toReturn.smsAvailable = true;
    if (toReturn.start && toReturn.end) {
      const diffMins = differenceInMinutes(
        new Date(toReturn.start),
        inputZoned
      );
      // console.log("diffMins", diffMins);
      if (diffMins <= 90) toReturn.smsAvailable = true; // On affiche le bouton envoyer les SMS que si on est à 15 minutes de l'heure de vente
    }
    return toReturn;
  });
};
