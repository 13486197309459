import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";

export type MaintenanceToDo = {
  operation: string;
  priority: string;
  [key: string]: any;
};

export type Locker = {
  _id?: string;
  maintenanceToDo?: MaintenanceToDo;
  available?: boolean;
  type: "bio" | "standard";
  size: "standard" | "extended";
  number: number;
  fillingNumber?: number;
  trailerId?: string;
};

export type Cabinet = {
  _id?: string;
  maintenanceToDo?: MaintenanceToDo;
  available?: boolean;
  size?: "standard" | "extended";
  side?: "left" | "right";
  lockers?: Locker[];
  createdAt?: string;
  updatedAt?: string;
};

export type Trailer = {
  _id?: string;
  technicalControl: {
    name: string;
    phoneNumber: string;
    [key: string]: any;
  };
  maintenanceToDo: MaintenanceToDo;
  state: string;
  networkState?: {
    networkMod: string;
    linkStatus: string;
    signalLevel: string;
    ssid: string;
    stickStatus: boolean;
  };
  location: [number, number];
  name: string;
  maxPayload: number;
  description: string;
  cabinetType: string;
  cabinets: Cabinet[];
  online_cabinets: number;
  total_cabinets: number;
  total_standard_cabinets: number;
  total_freezer_cabinets: number;
  online_tablets: number;
  total_tablets: number;
  battery_voltage: number;
  remaining_orders: number;
  total_orders: number;
  online_motherboard: boolean;
  online_server: boolean;
  active: boolean;
  matriculation: string;
  manufacturedDate: string;
  startingDate: string;
  unloadedWeight: number;
  ptac: number;
  kilometrage: number;
  lastKilometrageUpdate: string;
  lastTechnicalVisit: string;
  lastTireChange: string;
  lastBrakePadChange: string;
  lastSanitaryControl: string;
  nextSanitaryControl: string;
  certifierName: string;
  furtherInformation: string;
  createdAt?: string;
  updatedAt?: string;
  [key: string]: any;
};

type TrailersState = {
  list: Array<Trailer>;
  selectedTrailerId: Trailer["_id"];
  isLoading: boolean;
};

let initialState: TrailersState = {
  list: [],
  selectedTrailerId: "",
  isLoading: false,
};

const trailersSlice = createSlice({
  name: "trailers",
  initialState,
  reducers: {
    trailersLoading(state) {
      state.isLoading = true;
    },
    trailersLoaded(state) {
      state.isLoading = false;
    },
    setTrailers(state, action: PayloadAction<[Trailer]>) {
      state.list = action.payload;
    },
    removeTrailer(state, action: PayloadAction<string>) {
      const _id = action.payload;
      state.list = state.list.filter((el) => el._id !== _id);
    },
    modifyTrailer(state, action: PayloadAction<Trailer>) {
      const { _id } = action.payload;
      state.list = state.list.map((trailer) => {
        if (trailer._id === _id) {
          return action.payload;
        } else {
          return trailer;
        }
      });
    },
    addTrailer(state, action: PayloadAction<Trailer>) {
      const newTrailer = action.payload;
      state.list = [...state.list, newTrailer];
    },
    setSelectedTrailerId(state, action: PayloadAction<Trailer["_id"]>) {
      const trailerId = action.payload;
      state.selectedTrailerId = trailerId;
    },
  },
});

export const {
  trailersLoading,
  trailersLoaded,
  setTrailers,
  removeTrailer,
  modifyTrailer,
  addTrailer,
  setSelectedTrailerId,
} = trailersSlice.actions;

export default trailersSlice.reducer;

const selectAllTrailers = (state: RootState) => state.trailers.list;

export const selectTrailerLockers = (trailerId: string) =>
  createSelector([selectAllTrailers], (trailers) => {
    const selectedTrailer = trailers.find((t) => t._id === trailerId);
    let lockers: Locker[] = [];
    selectedTrailer?.cabinets.forEach((cabinet) => {
      if (cabinet.lockers) {
        lockers = lockers.concat(cabinet.lockers);
      }
    });
    return lockers;
  });
