export const translate = (text: string): string => {
  if (translateObject[text]) return translateObject[text];
  else return `Traduction pour "${text}" non disponible`;
};

const translateObject: { [key: string]: string } = {
  name: "Nom",
  firstname: "Prénom",
  lastname: "Nom",
  role: "Rôle",
  description: "Description",
  postCode: "Code postal",
  numberOfClients: "Nombre de clients",
  kilometrage: "Kilometrage",
  lastKilometrageUpdate: "Dernière mise à jour du kilométrage",
  furtherInformation: "Informations complémentaires",
  technicalControl: "Contrôle technique",
  phoneNumber: "Numéro de téléphone",
  maintenanceToDo: "Maintenance à effectuer",
  operation: "Opération",
  priority: "Priorité",
  state: "État",
  location: "Localisation",
  online_cabinets: "Armoires connectées",
  total_cabinets: "Total armoires",
  total_standard_cabinets: "Total armoires standards",
  total_freezer_cabinets: "Total armoires réfrigérées",
  online_tablets: "Tablettes connectées",
  total_tablets: "Total tablettes",
  remaining_orders: "Commandes restantes",
  total_orders: "Total commandes",
  available: "Point relais actif",
  active: "Drive Mobile actif",
  matriculation: "Immatriculation",
  manufacturedDate: "Date de fabrication",
  startingDate: "Date de mise en service",
  unloadedWeight: "Poids à vide",
  ptac: "PTAC",
  lastTechnicalVisit: "Dernière inspection technique",
  lastTireChange: "Dernier changement des pneus",
  lastSanitaryControl: "Dernier contrôle sanitaire",
  nextSanitaryControl: "Prochain contrôle sanitaire",
  lastBrakePadChange: "Dernier changement des plaquettes",
  certifierName: "Nom du certificateur",
  createdAt: "Créé(e) le",
  updatedAt: "Mis à jour le",
  address: "Adresse",
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
  entity: "Entité",
  type: "Type",
  email: "Adresse email",
  creationContract: "Date de création du contrat",
  begginingContract: "Date de début du contrat",
  endingContract: "Date de fin du contrat",
  typeOfTrailerHitch: "Type d'attache de la remorque",
  date: "Date",
  dateOrderToSend: "Date des commandes à envoyer à la remorque",
  standBy: "Stand-By",
  batteryCharging: "Chargement batteries",
  trailerLoading: "Chargement de la remorque",
  trailerLoaded: "Chargement terminé",
  transit: "Transit aller/retour",
  saleSetup: "Mise en config. vente",
  sale: "Vente",
  nightSale: "Vente nuit",
  saleUnSetup: "Désinstallation vente",
  trailerOFF: "Remorque OFF",
  trailerON: "Remorque ON",
  manualOverride: "⚠️ Manuel (Admin)",
  trailerUnLoading: "Déchargement de la remorque / nettoyage",
  openingHours: "Horaires d'ouverture",
  driversLicense: "Permis",
  password: "Mot de passe",
  outward: "Transit aller",
  admin: "Administrateur",
  operator: "Opérateur",
  manager: "Manager",
  transporter: "Transporteur",
  provider: "Prestataire de services",
  seller: "Vendeur de produits",
  transportType: "Type de trajet",
  high: "Bloquant",
  low: "Non-bloquant",
  driversLicenseType: "Type de permis",
  driversLicenseNumber: "Numéro de permis",
  deliveredOn: "Délivré le",
  deliveredBy: "Délivré par",
  idCardPhotocopy: "Photocopie recto-verso de la carte d'identité classée",
  driversLicensePhotocopy: "Photocopie recto-verso du permis classée",
  socialSecurityNumber: "Numéro de sécurité sociale",
  enablePushNotification: "Notification Push activée",
  shop: "Magasin",
  url: "Lien vers le site du magasin",
  company: "Entreprise",
  companyName: "Nom de l'entreprise",
  companyActivity: "Activité de l'entreprise",
  companyAddress: "Adresse de l'entreprise",
  companyWebsite: "Site internet de l'entreprise",
  proxy: "Mandataire",
  customerReference: "Référence client",
  emptyMass: "Masse à vide (kg)",
  volume: "Volume (L)",
  capacity: "Capacité (L)",
  buyingPrice: "Prix d'achat",
  sellingPrice: "Prix de vente",
  depositPrice: "Prix de dépôt",
  comment: "Commentaire",
  eutectic: "Plaque eutectique",
  eutecticQuantity: "Quantité eutectique",
  eutecticReference: "Référence eutectique",
  eutecticMass: "Masse eutectique (kg)",
  eutecticType: "Type eutectique",
  eutecticTemperature: "Température eutectic",
  storageVolumePerCalculationUnitLiter: "Vol. stockage par unité de calc [L]",
  storageTemperature: "Température de stockage",
  boxId: "Box",
  crateId: "Cagette",
  conditioning: "Contenant élémentaire",
  conditioningToDo: "Conditionnement à faire",
  mass: "Poids produit (kg)",
  totalMass: "Poids total",
  maxPayload: "Charge maximale admissible",
  volumetricUnit: "Unité de gestion de volume",
};
