import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
export interface Route {
  name: string;
  url: string;
}

export interface SimpleRoute extends Route {
  type: "simple";
}

export interface MultipleRoutes {
  type: "multiple";
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  menuTitle: string;
  items: Route[];
}

export const routes: (SimpleRoute | MultipleRoutes)[] = [
  {
    type: "simple",
    name: "Accueil",
    url: "/",
  },
  {
    type: "simple",
    name: "OpenVidu",
    url: "/video",
  },
  {
    type: "multiple",
    menuTitle: "Configuration",
    icon: EditIcon,
    items: [
      {
        name: "Communes",
        url: "/cities",
      },
      {
        name: "Drives Mobiles",
        url: "/trailers",
      },
      {
        name: "Import / Export",
        url: "/import",
      },
      {
        name: "Utilisateurs",
        url: "/usersadmin",
      },
      {
        name: "Produits",
        url: "/products",
      },
      {
        name: "Contenants primaires",
        url: "/basiccontainers",
      },
      {
        name: "Contenants secondaires",
        url: "/globalcontainers",
      },
      {
        name: "Plaques eutectiques",
        url: "/eutecticplates",
      },
    ],
  },
  {
    type: "multiple",
    menuTitle: "Supervision",
    icon: VisibilityIcon,
    items: [
      {
        name: "Carte",
        url: "/map",
      },
      {
        name: "Drives Mobiles",
        url: "/trailersstate",
      },
      {
        name: "Drives Mobiles 2 (W.I.P)",
        url: "/trailerscontrol",
      },
      {
        name: "Planning",
        url: "/planning",
      },
      {
        name: 'Commandes en "retrait"',
        url: "/socleoOrders",
      },
      {
        name: 'Commandes en "dépôt"',
        url: "/prestashopOrders",
      },
      {
        name: "Import / Exports",
        url: "/exports",
      },
    ],
  },
  {
    type: "simple",
    name: "Documentation",
    url: "/api/doc/",
  },
];

// const isAuthenticated = useSelector(
//   (state: RootState) => state.auth.isAuthenticated
// );

// if (isAuthenticated) console.log("oui");
